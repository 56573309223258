

























































































































































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import {
  mdiAccountCog,
  mdiArchiveCogOutline,
  mdiCheck,
  mdiEmail,
  mdiInformation,
  mdiLogin,
  mdiLogout,
  mdiPlaylistEdit,
  mdiRefresh,
  mdiShapeOutline,
  mdiTagText,
} from "@mdi/js";
import { Location } from "vue-router";
import { getFirebaseAuth } from "@/plugins/firebase";
import { getPlatformName } from "@/plugins/utils";
import AddListButton from "@/components/AddListButton.vue";
import {
  AppData,
  List,
  NotificationRequest,
  User,
  Notification,
} from "@/types/state";

interface MenuItem {
  name: string;
  icon: string;
  secondary?: string;
  route: Location;
  adminOnly?: boolean;
  hasAccount?: boolean;
  isNotAnonymous?: boolean;
}

@Component({
  components: { AddListButton },
})
export default class App extends Vue {
  logoutIcon: string = mdiLogout;
  emailIcon: string = mdiEmail;
  blogIcon: string = mdiTagText;
  tickIcon: string = mdiCheck;
  infoIcon: string = mdiInformation;
  refreshIcon: string = mdiRefresh;
  settingsIcon: string = mdiAccountCog;
  loginIcon: string = mdiLogin;

  @Getter("appData") appData!: AppData;
  @Getter("loading") loading!: boolean;
  @Getter("title") title!: string;
  @Getter("user") user!: User;
  @Getter("navDrawerOpen") navDrawerOpen!: boolean;
  @Getter("notification") notification!: Notification;
  @Getter("isLoggedIn") isLoggedIn!: boolean;
  @Getter("listIcon") listIcon!: (name: string) => string;
  @Getter("hasProfilePicture") hasProfilePicture!: boolean;
  @Getter("lists") lists!: Array<List>;
  @Action("disableNotification") disableNotification!: () => void;
  @Action("resetState") resetState!: () => void;
  @Action("setNavDrawer") setNavDrawer!: (isOpen: boolean) => void;
  @Action("addNotification") addNotification!: (
    request: NotificationRequest
  ) => void;

  get version(): string {
    return (process.env.VUE_APP_COMMIT_HASH as string).slice(0, 7);
  }

  get githubLInk(): string {
    return process.env.VUE_APP_GITHUB_LINK as string;
  }

  get navDrawerActive(): boolean {
    return this.navDrawerOpen && this.$route.meta?.showNav === true;
  }

  set navDrawerActive(value: boolean) {
    this.setNavDrawer(value);
  }

  get notificationActive(): boolean {
    return this.notification.active;
  }

  set notificationActive(state: boolean) {
    this.disableNotification();
  }

  get titleLeftPadding(): number {
    if (this.$vuetify.breakpoint.mdAndDown || !this.navDrawerOpen) {
      return 0;
    }
    return 130;
  }

  get menuItems(): Array<MenuItem> {
    return this.lists.map((list) => {
      return {
        name: list.name,
        secondary: `${list.items.length} item${
          list.items.length === 1 ? "" : "s"
        }`,
        icon: this.listIcon(list.icon),
        route: {
          name: this.$constants.ROUTE_NAMES.SHOPPING_LIST_SHOW,
          params: { listId: list.id },
        },
      };
    });
  }

  get manageMenuItems(): Array<MenuItem> {
    return [
      {
        name: "Manage Lists",
        icon: mdiPlaylistEdit,
        route: {
          name: this.$constants.ROUTE_NAMES.MANAGE_LISTS,
        },
      },
      {
        name: "Manage Categories",
        icon: mdiShapeOutline,
        route: {
          name: this.$constants.ROUTE_NAMES.MANAGE_CATEGORIES,
        },
      },
      {
        name: "Manage Items",
        icon: mdiArchiveCogOutline,
        route: {
          name: this.$constants.ROUTE_NAMES.MANAGE_ITEMS,
        },
      },
    ];
  }

  mounted(): void {
    this.setThemeColor();
    this.listenForDarkMode();
  }

  listenForDarkMode(): void {
    const darkMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    try {
      // Chrome & Firefox
      darkMediaQuery.addEventListener("change", (event) => {
        this.$vuetify.theme.dark = event.matches;
        this.setThemeColor();
      });
    } catch (error) {
      try {
        // Safari
        darkMediaQuery.addListener(() => {
          this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
          this.setThemeColor();
        });
      } catch (innerError) {
        console.error(innerError);
      }
    }
  }

  setThemeColor(): void {
    document
      .querySelector('meta[name="theme-color"]')
      ?.setAttribute("content", this.themeColor);
  }

  get themeColor(): string {
    if (this.$vuetify.theme.dark) {
      return "#000000";
    }
    return "#AFB42B";
  }

  goHome(): void {
    this.$router
      .push({
        name: this.$constants.ROUTE_NAMES.HOME,
      })
      .catch((e: Error) => {
        if (e.message.includes("Redirected")) {
          return;
        }
        Promise.reject(e.message);
      });
  }

  refreshApp(): void {
    if (!("serviceWorker" in navigator)) {
      return;
    }
    navigator.serviceWorker
      .getRegistrations()
      .then((registrations) => {
        const promises = Array<Promise<boolean>>();
        registrations.forEach((registration) => {
          promises.push(registration.unregister());
        });
        return Promise.all(promises);
      })
      .then(() =>
        caches.keys().then((keys) => keys.map((key) => caches.delete(key)))
      )
      .then(() => {
        this.addNotification({
          type: "success",
          message: `This ${getPlatformName()} will reload in a few seconds.`,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(console.error);
  }

  logout(): void {
    getFirebaseAuth()
      .signOut()
      .then(() => {
        this.resetState();
        this.addNotification({
          type: "info",
          message: "You have successfully logged out",
        });
        this.$router.push({ name: this.$constants.ROUTE_NAMES.HOME });
      });
  }
}
