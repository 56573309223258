












import { Vue, Component, Prop } from "vue-property-decorator";
import { mdiArrowLeft } from "@mdi/js";
import { Location } from "vue-router";
@Component
export default class BackButton extends Vue {
  @Prop({ required: false }) route?: Location;
  @Prop({ required: false, type: Boolean, default: false }) block!: boolean;
  backIcon: string = mdiArrowLeft;
  goBack(): void {
    if (this.route) {
      this.$router.push(this.route);
      return;
    }
    if (window.history.length > 1) {
      this.$router.back();
      return;
    }
    this.$router.push({
      name: this.$constants.ROUTE_NAMES.HOME,
    });
  }
}
