
































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import {
  mdiCart,
  mdiClose,
  mdiDelete,
  mdiPlus,
  mdiNoteTextOutline,
  mdiNotificationClearAll,
} from "@mdi/js";
import { CATEGORY_ID_UNCATEGORIZED } from "@/store";
import { dialogWidth } from "@/plugins/vuetify";
import Driver from "driver.js";
import ShoppingListDemoBanner from "@/components/ShoppingListDemoBanner.vue";
import {
  Category,
  List,
  Item,
  MaterializedList,
  MaterializedListItem,
  SelectItem,
  UpdateItemRequest,
} from "@/types/state";
@Component({
  components: { ShoppingListDemoBanner },
})
export default class ShoppingList extends Vue {
  itemName = "";
  isBlur = false;
  deleteIcon: string = mdiDelete;
  selectedItem: null | number = -1;
  dialog = false;
  formValid = false;
  cartIcon: string = mdiCart;
  addIcon: string = mdiPlus;
  closeIcon: string = mdiClose;
  notesIcon: string = mdiNoteTextOutline;
  clearCartIcon: string = mdiNotificationClearAll;
  formItemId = "";
  formName = "";
  formAddedToCart = false;
  formCategoryId = CATEGORY_ID_UNCATEGORIZED;
  formUnit = "";
  formNameRules = [
    (value: string | null): boolean | string =>
      (!!value && value.trim() != "") || "Name is required",
    (value: string | null): boolean | string =>
      (value && value.length <= 50) || "Name must be less than 50 characters",
  ];
  formQuantity = 1;
  formQuantityRules = [
    (value: number | null): boolean | string =>
      !!value || "Quantity is required",
    (value: number | null | string): boolean | string =>
      value == "" ||
      value == null ||
      (value > 0 && value <= 999) ||
      "Quantity must be between 1 and 999",
  ];
  formPricePerUnit = 0.0;
  formPricePerUnitRules = [
    (value: number | null | string): boolean | string => {
      console.log(value);
      return (
        value === null ||
        value === "" ||
        value >= 0 ||
        "Price per unit must be more than " + this.currencyFormat(0)
      );
    },
  ];
  formNotes = "";
  formNotesRules = [
    (value: string | null): boolean | string =>
      !value ||
      (value && value.length <= 1000) ||
      "Notes must be less than 300 characters",
  ];
  addFormQuantity = 0;

  @Getter("cartPanel") cartPanel!: number;
  @Getter("listMaterializedItems") listItems!: MaterializedList;
  @Getter("cartMaterializedItems") cartItems!: MaterializedList;
  @Getter("selectedList") list!: List;
  @Getter("cartTotal") cartTotal!: number;
  @Getter("listTotal") listTotal!: number;
  @Getter("currency") currency!: string;
  @Getter("itemUnitSelectItems") itemUnits!: string;
  @Getter("saving") saving!: boolean;
  @Getter("currencySymbol") currencySymbol!: string;
  @Getter("loadingState") loadingState!: boolean;
  @Getter("stateLoaded") stateLoaded!: boolean;
  @Getter("listExists") listExists!: (listId: string) => boolean;
  @Getter("itemUnitName") unitName!: (unit: string, quantity: number) => string;
  @Getter("formatCurrency") formatCurrency!: (value: number) => string;
  @Getter("autocompleteItems") autocompleteItems!: Array<SelectItem>;
  @Getter("categorySelectItems") categorySelectItems!: Array<SelectItem>;
  @Getter("showIntro") showIntro!: boolean;

  @Action("setTitle") setTitle!: (title: string) => void;
  @Action("addItem") addItem!: (name: string) => void;
  @Action("setShowIntro") setShowIntro!: (show: boolean) => void;
  @Action("updateItem") updateItem!: (request: UpdateItemRequest) => void;
  @Action("deleteListItem") deleteListItem!: (itemId: string) => void;
  @Action("loadState") loadState!: () => Promise<void>;
  @Action("toggleCartPanel") toggleCartPanel!: () => void;
  @Action("addToCart") addToCart!: (itemId: string) => void;
  @Action("removeFromCart") removeFromCart!: (itemId: string) => void;
  @Action("setSelectedListId") setSelectedListId!: (listId: string) => void;
  @Action("setTitleByListId") setTitleByListId!: (listId: string) => void;
  @Action("emptyCartItems") emptyCartItems!: (listId: string) => void;

  get dialogWidth(): string {
    return dialogWidth(this.$vuetify.breakpoint.name);
  }

  closePopup(): void {
    this.clearForm();
    this.dialog = false;
  }

  onPopupDelete(): void {
    this.deleteListItem(this.formItemId);
    this.closePopup();
  }

  clearForm(): void {
    this.formName = "";
    this.formQuantity = 1;
    this.formNotes = "";
    this.formItemId = "";
    this.formCategoryId = CATEGORY_ID_UNCATEGORIZED;
    this.formPricePerUnit = 0.0;
    this.formAddedToCart = false;
    this.formUnit = "";
  }

  setFormItem(item: MaterializedListItem): void {
    this.formItemId = item.item.id;
    this.formName = item.item.name;
    this.formQuantity = item.listItem.quantity;
    this.formNotes = item.listItem.notes;
    this.formCategoryId = item.item.categoryId;
    this.formPricePerUnit = item.item.pricePerUnit;
    this.formAddedToCart = item.listItem.addedToCart;
    this.formUnit = item.item.unit ?? "";
  }

  mounted(): void {
    this.loadState().then(() => {
      this.setTitle(this.list.name);
      if (this.list.id !== this.$route.params.listId) {
        if (!this.listExists(this.$route.params.listId)) {
          this.$router.push({
            name: this.$constants.ROUTE_NAMES.SHOPPING_LIST_SHOW,
            params: {
              listId: this.list.id,
            },
          });
        } else {
          this.setSelectedListId(this.$route.params.listId);
          this.setTitleByListId(this.$route.params.listId);
        }
      }

      if (this.showIntro) {
        setTimeout(() => {
          this.loadIntroductions();
        }, 5000);
      }
    });
  }

  @Watch("$route.params.listId")
  onListIdChange(newListId: string): void {
    this.setSelectedListId(newListId);
    this.setTitleByListId(newListId);
  }

  get totalPrice(): number {
    return this.formPricePerUnit * this.formQuantity;
  }

  categoryClass(category: Category): { [p: string]: boolean } {
    return {
      [`${category.color || "teal"}--text`]: true,
    };
  }

  currencyFormat(value: number): string {
    return this.formatCurrency(value);
  }

  onFocus(): void {
    this.addFormQuantity = 0;
    this.isBlur = false;
  }

  onBlur(): void {
    this.isBlur = true;
  }

  loadIntroductions(): void {
    const driver = new Driver({
      onReset: () => {
        this.setShowIntro(false);
      },
    });
    // Define the steps for introduction
    driver.defineSteps([
      {
        element: "#add-item-input",
        popover: {
          title: "Add Item",
          description: `Add an item to your shopping list.<br/>Type <b>10 Bananas</b> to add banana in your shopping list with quantity of <b>10</b>.`,
        },
      },
      {
        element: "#list-category-title-0",
        popover: {
          title: "Categories",
          description: `Shopping list items are grouped by categories with <em>fancy colors</em>.`,
        },
      },
      {
        element: "#list-item-details-0-0",
        popover: {
          title: "Edit Item",
          description: `Change the price or quantity of items in your shopping list`,
        },
      },
      {
        element: "#list-item-checkbox-0-0",
        popover: {
          title: "Move to Cart",
          description: `Move items from your list to the shopping cart.`,
        },
      },
      {
        element: "#list-item-delete-0-0",
        popover: {
          title: "Delete Item",
          description: `Remove items from your list.`,
        },
      },
      {
        element: "#header-drawer-btn",
        stageBackground: "transparent",
        popover: {
          title: "List Settings",
          position: "right",
          description: `Add multiple shopping lists and manage your items and categories`,
        },
      },
      {
        element: "#header-account-settings",
        stageBackground: "transparent",
        popover: {
          title: "Account Settings",
          description: `Logout, manage your settings even delete your account`,
          position: "left",
        },
      },
    ]);
    // Start the introduction
    driver.start();
  }

  onClearCart(): void {
    this.emptyCartItems(this.list.id);
  }

  onSave(): void {
    this.updateItem({
      itemId: this.formItemId,
      name: this.formName,
      categoryId: this.formCategoryId,
      quantity: this.formQuantity,
      notes: this.formNotes,
      unit: this.formUnit,
      pricePerUnit: this.formPricePerUnit,
      addedToCart: this.formAddedToCart,
    });
    this.dialog = false;
    this.clearForm();
  }

  itemClicked(item: MaterializedListItem): void {
    this.setFormItem(item);
    this.dialog = true;
    setTimeout(() => {
      this.selectedItem = null;
    }, 200);
  }

  itemFilter(item: Item, queryText: string, itemText: string): boolean {
    if (this.hasQuantity(queryText)) {
      this.addFormQuantity = parseFloat(queryText.split(" ")[0]);
      queryText = queryText.split(" ").slice(1).join(" ");
    } else {
      this.addFormQuantity = 0;
    }
    return (
      itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    );
  }

  hasQuantity(value: string): boolean {
    const nameQuantity = parseFloat(value.split(" ")[0]);
    return !isNaN(nameQuantity) && nameQuantity > 0;
  }

  onChange(chosenItem: string | SelectItem): void {
    let old = "";
    if (!this.isBlur) {
      if (typeof chosenItem == "string") {
        if (chosenItem.trim().length > 15) {
          old = chosenItem.trim();
        }
        this.addItem(chosenItem);
      } else {
        this.addItem(
          (this.addFormQuantity > 0 ? this.addFormQuantity + " " : "") +
            chosenItem.text
        );
      }
    }

    this.$nextTick(() => {
      this.itemName = old;
      this.$nextTick(() => {
        document.getElementById("page-title")?.click();
      });
    });
  }
}
