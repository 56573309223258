import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"6","md":"8","offset-md":"2","offset-lg":"3"}},[_c('shopping-list-demo-banner'),_c('div',{staticClass:"d-flex",class:{
          'justify-center':
            _vm.categories.length === 0 && _vm.$vuetify.breakpoint.mdAndDown,
        }},[_c(VBtn,{attrs:{"disabled":_vm.synchronizing},on:{"click":_vm.onSync}},[(_vm.$vuetify.breakpoint.lgAndUp)?_c(VIcon,[_vm._v(_vm._s(_vm.syncIcon))]):_vm._e(),_vm._v(" Synchronize ")],1),_c(VSpacer),_c(VDialog,{attrs:{"max-width":_vm.dialogWidth},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mb-4",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[(_vm.$vuetify.breakpoint.lgAndUp)?_c(VIcon,[_vm._v(_vm._s(_vm.addIcon))]):_vm._e(),_vm._v(" Add Category ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.formTitle)+" "),_c(VSpacer),_c(VBtn,{attrs:{"color":"info","icon":""},on:{"click":_vm.closePopup}},[_c(VIcon,[_vm._v(_vm._s(_vm.closeIcon))])],1)],1),_c(VCardText,[_c(VForm,{attrs:{"lazy-validation":""},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c(VTextField,{staticClass:"mt-2",attrs:{"aria-required":"true","disabled":_vm.saving,"rules":_vm.formNameRules,"label":"Name","counter":"15","persistent-placeholder":"","placeholder":"e.g Meat","outlined":""},model:{value:(_vm.editedCategory.name),callback:function ($$v) {_vm.$set(_vm.editedCategory, "name", $$v)},expression:"editedCategory.name"}}),_c(VSelect,{staticClass:"mt-2",attrs:{"disabled":_vm.saving,"items":_vm.categoryColors,"outlined":"","label":"Color"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var item = ref.item;
        var attrs = ref.attrs;
        var on = ref.on;
return [_c(VListItem,_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c(VListItemTitle,[_vm._v(_vm._s(item.text))]),_c(VListItemAction,[_c(VIcon,{attrs:{"color":item.value}},[_vm._v(" "+_vm._s(_vm.squareIcon)+" ")])],1)],1)]}},{key:"append",fn:function(){return [_c(VIcon,{attrs:{"color":_vm.editedCategory.color}},[_vm._v(" "+_vm._s(_vm.squareIcon)+" ")])]},proxy:true}]),model:{value:(_vm.editedCategory.color),callback:function ($$v) {_vm.$set(_vm.editedCategory, "color", $$v)},expression:"editedCategory.color"}})],1)],1),_c(VCardActions,{staticClass:"mt-n8"},[_c(VBtn,{attrs:{"text":"","color":"success","disabled":!_vm.formValid || _vm.saving},on:{"click":_vm.onSave}},[_vm._v("Save")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"info","text":""},on:{"click":_vm.closePopup}},[_vm._v("Cancel")])],1)],1)],1)],1),_c(VCard,{attrs:{"tile":""}},[_c(VCardText,{staticClass:"px-0 py-0"},[_c(VList,{staticClass:"pb-0 px-0",attrs:{"subheader":"","two-line":"","nav":""}},[_vm._l((_vm.categories),function(category,index){return [_c(VListItem,{key:category.id,staticClass:"mb-0",on:{"click":function($event){return _vm.onEditCategory(category)}}},[_c(VListItemAvatar,{attrs:{"rounded":""}},[_c(VIcon,{attrs:{"color":category.color}},[_vm._v(_vm._s(_vm.squareIcon))])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-overline",domProps:{"textContent":_vm._s(category.name)}}),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.categoryItemsCount(category.id) + " item" + (_vm.categoryItemsCount(category.id) === 1 ? "" : "s"))+" ")])],1),_c(VListItemAction,[_c('div',{staticClass:"d-flex"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"info","icon":""},on:{"click":function($event){return _vm.onEditCategory(category)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(_vm.editIcon))])],1)]}}],null,true)},[_c('span',[_vm._v("Edit "+_vm._s(category.name))])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"error","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onDeleteList(category)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(_vm.deleteIcon))])],1)]}}],null,true)},[_c('span',[_vm._v("Delete "+_vm._s(category.name))])])],1)])],1),(index < _vm.categories.length - 1)?_c(VDivider,{key:index,staticClass:"mt-0"}):_vm._e()]})],2)],1)],1),_c(VDialog,{attrs:{"max-width":_vm.dialogWidth,"width":"400"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_c('div',{staticClass:"text-break"},[_vm._v(" Are you sure you want to delete "),_c('code',{staticClass:"d-inline-block"},[_vm._v(_vm._s(this.editedCategory.name))]),_vm._v(" ? ")])]),_c(VCardActions,[_c(VBtn,{attrs:{"color":"info","text":""},on:{"click":_vm.closeDeleteDialog}},[_vm._v("No")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":_vm.onDeleteListConfirm}},[_vm._v("Yes")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }