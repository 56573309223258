



































































































































































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { CATEGORY_ID_UNCATEGORIZED } from "@/store";
import { dialogWidth } from "@/plugins/vuetify";
import {
  mdiClose,
  mdiMagnify,
  mdiPlus,
  mdiSquareEditOutline,
  mdiSync,
  mdiTrashCan,
} from "@mdi/js";
import ShoppingListDemoBanner from "@/components/ShoppingListDemoBanner.vue";
import { Item, List, UpsertItemRequest } from "@/types/state";

@Component({
  components: { ShoppingListDemoBanner },
})
export default class ManageItems extends Vue {
  @Getter("formatCurrency") formatCurrency!: (value: number) => string;
  formNameRules = [
    (value: string | null): boolean | string =>
      (!!value && value.trim() != "") || "Category name is required",
    (value: string | null): boolean | string =>
      (value && value.length <= 15) ||
      "Category name must be less than 15 characters",
  ];

  formPricePerUnitRules = [
    (value: number | null | string): boolean | string =>
      (!Number.isNaN(value) && value != null && value != "" && value >= 0) ||
      "Price per unit must be at least " + this.currencyFormat(0),
  ];
  formValid = false;
  formQuery = "";
  itemSize = 20;
  addIcon: string = mdiPlus;
  syncIcon: string = mdiSync;
  searchIcon: string = mdiMagnify;
  editIcon: string = mdiSquareEditOutline;
  deleteIcon: string = mdiTrashCan;
  closeIcon: string = mdiClose;
  dialog = false;
  dialogDelete = false;
  synchronizing = false;
  editedItem: UpsertItemRequest = {
    name: "",
    unit: "",
    itemId: "",
    pricePerUnit: 0,
    categoryId: CATEGORY_ID_UNCATEGORIZED,
  };

  defaultItem: UpsertItemRequest = {
    name: "",
    itemId: "",
    unit: "",
    pricePerUnit: 0,
    categoryId: CATEGORY_ID_UNCATEGORIZED,
  };
  @Getter("currencySymbol") currencySymbol!: string;
  @Getter("categorySelectItems") categories!: Array<List>;
  @Getter("items") items!: Array<Item>;
  @Getter("itemUnitSelectItems") itemUnits!: string;
  @Getter("saving") saving!: boolean;
  @Getter("itemListsCount") itemListsCount!: (itemId: string) => number;
  @Getter("currency") currency!: string;
  @Getter("findCategoryNameByItemId") categoryNameByItemId!: (
    itemId: string
  ) => string;
  @Action("upsertItem") upsertItem!: (
    request: UpsertItemRequest
  ) => Promise<void>;
  @Action("deleteItem") deleteItem!: (itemId: string) => Promise<void>;
  @Action("setTitle") setTitle!: (title: string) => void;
  @Action("loadState") loadState!: () => Promise<void>;
  @Action("syncItems") syncItems!: () => Promise<void>;

  get dialogWidth(): string {
    return dialogWidth(this.$vuetify.breakpoint.name);
  }

  get canLoadMore(): boolean {
    return this.filteredItems.length > this.itemSize;
  }

  get filteredItems(): Array<Item> {
    const query = this.formQuery.trim().toLowerCase();
    if (query === "") {
      return this.items;
    }

    return this.items.filter((item) => {
      return (
        item.name.toLowerCase().indexOf(query) !== -1 ||
        this.categoryNameByItemId(item.id).toLowerCase().indexOf(query) !== -1
      );
    });
  }

  get formTitle(): string {
    return this.editedItem.itemId === this.defaultItem.itemId
      ? "Add Item"
      : "Edit Item";
  }

  mounted(): void {
    this.loadState();
    this.setTitle("Manage Items");
  }

  currencyFormat(value: number): string {
    return this.formatCurrency(value);
  }

  closePopup(): void {
    this.clearForm();
    this.dialog = false;
  }

  closeDeleteDialog(): void {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.clearForm();
    });
  }

  async onSave(): Promise<void> {
    await this.upsertItem({ ...this.editedItem });
    this.dialog = false;
    this.clearForm();
  }

  onDeleteItem(item: Item): void {
    this.editedItem = { ...item, itemId: item.id };
    this.dialogDelete = true;
  }

  onDeleteListConfirm(): void {
    this.deleteItem(this.editedItem.itemId);
    this.closeDeleteDialog();
  }

  onEditItem(item: Item): void {
    this.editedItem = { ...item, itemId: item.id };
    this.dialog = true;
  }

  async onSync(): Promise<void> {
    this.synchronizing = true;
    await this.syncItems();
    this.synchronizing = false;
  }

  clearForm(): void {
    this.editedItem = {
      ...this.defaultItem,
    };
  }
}
