import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"6","md":"8","offset-md":"2","offset-lg":"3"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Account Settings")]),_c(VCardText,[_c(VForm,{attrs:{"lazy-validation":""},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c(VSelect,{staticClass:"mt-2",attrs:{"disabled":_vm.saving,"items":_vm.currencies,"outlined":"","label":"Currency"},model:{value:(_vm.formCurrency),callback:function ($$v) {_vm.formCurrency=$$v},expression:"formCurrency"}})],1)],1),_c(VCardActions,{staticClass:"mt-n8"},[_c(VBtn,{attrs:{"text":"","color":"success","disabled":!_vm.formValid || _vm.saving},on:{"click":_vm.onSave}},[_vm._v("Save")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"error","text":"","disabled":!_vm.isLoggedIn},on:{"click":_vm.onDeleteAccount}},[_vm._v("Delete Account")])],1)],1),_c('div',{staticClass:"text-center mt-4"},[_c('back-button')],1),_c(VDialog,{attrs:{"max-width":_vm.dialogWidth,"width":"400"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" Are you sure you want to delete your account? ")]),_c(VCardText,[_vm._v(" This action is permanent and irreversible! ")]),_c(VCardActions,[_c(VBtn,{attrs:{"color":"info","text":""},on:{"click":_vm.closeDeleteDialog}},[_vm._v("No")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":_vm.onDeleteListConfirm}},[_vm._v("Yes")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }