





























































import { Component } from "vue-property-decorator";
import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { LIST_ICON_DEFAULT } from "@/store";
import { dialogWidth } from "@/plugins/vuetify";
import { mdiClose, mdiPlus } from "@mdi/js";
import shortUUID from "short-uuid";
import { List, SelectItem, UpsertListRequest } from "@/types/state";

@Component
export default class AddListButton extends Vue {
  formNameRules = [
    (value: string | null): boolean | string =>
      (!!value && value.trim() != "") || "Name is required",
    (value: string | null): boolean | string =>
      (value && value.length <= 15) || "Name must be less than 15 characters",
  ];
  formName = "";
  formIcon = LIST_ICON_DEFAULT;
  dialog = false;
  formValid = false;
  addIcon: string = mdiPlus;
  closeIcon: string = mdiClose;

  @Getter("saving") saving!: boolean;
  @Getter("listIconSelectItems") listIcons!: Array<SelectItem>;
  @Getter("listIcon") listIcon!: (name: string) => string;
  @Getter("lists") lists!: Array<List>;
  @Action("upsertList") upsertList!: (
    request: UpsertListRequest
  ) => Promise<void>;

  closePopup(): void {
    this.clearForm();
    this.dialog = false;
  }

  clearForm(): void {
    this.formName = "";
    this.formIcon = LIST_ICON_DEFAULT;
  }

  get dialogWidth(): string {
    return dialogWidth(this.$vuetify.breakpoint.name);
  }

  get prependIcon(): string {
    return this.listIcon(this.formIcon);
  }

  async onSave(): Promise<void> {
    await this.upsertList({
      name: this.formName,
      icon: this.formIcon,
      id: shortUUID.generate(),
    });
    this.dialog = false;
    this.clearForm();

    await this.$router.push({
      name: this.$constants.ROUTE_NAMES.SHOPPING_LIST_SHOW,
      params: {
        listId: this.lists[this.lists.length - 1].id,
      },
    });
  }
}
