import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"90%","width":_vm.dialogWidth,"transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mx-auto",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(_vm.addIcon))]),_vm._v(" Create New List ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Create New List "),_c(VSpacer),_c(VBtn,{attrs:{"color":"info","icon":""},on:{"click":_vm.closePopup}},[_c(VIcon,[_vm._v(_vm._s(_vm.closeIcon))])],1)],1),_c(VCardText,[_c(VForm,{attrs:{"lazy-validation":""},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c(VTextField,{staticClass:"mt-2",attrs:{"aria-required":"true","disabled":_vm.saving,"rules":_vm.formNameRules,"label":"Name","counter":"15","persistent-placeholder":"","placeholder":"e.g Shopping List","outlined":""},model:{value:(_vm.formName),callback:function ($$v) {_vm.formName=$$v},expression:"formName"}}),_c(VSelect,{staticClass:"mt-2",attrs:{"disabled":_vm.saving,"items":_vm.listIcons,"append-icon":_vm.prependIcon,"outlined":"","label":"Icon"},model:{value:(_vm.formIcon),callback:function ($$v) {_vm.formIcon=$$v},expression:"formIcon"}})],1)],1),_c(VCardActions,{staticClass:"mt-n8"},[_c(VBtn,{attrs:{"text":"","color":"success","disabled":!_vm.formValid || _vm.saving},on:{"click":_vm.onSave}},[_vm._v("Save")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"info","text":""},on:{"click":_vm.closePopup}},[_vm._v("Cancel")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }