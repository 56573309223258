






















































































































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { LIST_ICON_DEFAULT } from "@/store";
import { dialogWidth } from "@/plugins/vuetify";
import shortUUID from "short-uuid";
import { mdiClose, mdiPlus, mdiSquareEditOutline, mdiTrashCan } from "@mdi/js";
import ShoppingListDemoBanner from "@/components/ShoppingListDemoBanner.vue";
import { List, SelectItem, UpsertListRequest } from "@/types/state";
@Component({
  components: { ShoppingListDemoBanner },
})
export default class ManageLists extends Vue {
  formNameRules = [
    (value: string | null): boolean | string =>
      (!!value && value.trim() != "") || "Name is required",
    (value: string | null): boolean | string =>
      (value && value.length <= 15) || "Name must be less than 15 characters",
  ];
  formValid = false;
  addIcon: string = mdiPlus;
  editIcon: string = mdiSquareEditOutline;
  deleteIcon: string = mdiTrashCan;
  closeIcon: string = mdiClose;
  dialog = false;
  dialogDelete = false;
  editedList: UpsertListRequest = {
    name: "",
    id: "",
    icon: LIST_ICON_DEFAULT,
  };

  defaultList: UpsertListRequest = {
    name: "",
    id: "",
    icon: LIST_ICON_DEFAULT,
  };

  @Getter("lists") lists!: Array<List>;
  @Getter("saving") saving!: boolean;
  @Getter("listIcon") listIcon!: (name: string) => string;
  @Getter("listIconSelectItems") listIcons!: Array<SelectItem>;

  @Action("upsertList") upsertList!: (
    request: UpsertListRequest
  ) => Promise<void>;
  @Action("deleteList") deleteList!: (listId: string) => Promise<void>;
  @Action("setTitle") setTitle!: (title: string) => void;
  @Action("loadState") loadState!: () => Promise<void>;

  get prependIcon(): string {
    return this.listIcon(this.editedList.icon);
  }

  get deleteDisabled(): boolean {
    return this.lists.length < 2;
  }

  get dialogWidth(): string {
    return dialogWidth(this.$vuetify.breakpoint.name);
  }

  get formTitle(): string {
    return this.editedList.id === this.defaultList.id
      ? "Create List"
      : "Edit List";
  }

  mounted(): void {
    this.loadState();
    this.setTitle("Manage Lists");
  }

  closePopup(): void {
    this.clearForm();
    this.dialog = false;
  }

  closeDeleteDialog(): void {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.clearForm();
    });
  }

  async onSave(): Promise<void> {
    await this.upsertList({
      name: this.editedList.name,
      icon: this.editedList.icon,
      id: this.editedList.id || shortUUID.generate(),
    });
    this.dialog = false;
    this.clearForm();
  }

  onDeleteList(list: List): void {
    this.editedList = { ...list };
    this.dialogDelete = true;
  }

  onDeleteListConfirm(): void {
    this.deleteList(this.editedList.id);
    this.closeDeleteDialog();
  }

  onEditList(list: List): void {
    this.editedList = { ...list };
    this.dialog = true;
  }

  clearForm(): void {
    this.editedList = {
      ...this.defaultList,
    };
  }
}
