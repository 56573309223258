



















































































































































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { CATEGORY_COLOR_TEAL } from "@/store";
import { dialogWidth } from "@/plugins/vuetify";
import {
  mdiClose,
  mdiPlus,
  mdiSquare,
  mdiSquareEditOutline,
  mdiSync,
  mdiTrashCan,
} from "@mdi/js";
import ShoppingListDemoBanner from "@/components/ShoppingListDemoBanner.vue";
import {
  Category,
  Item,
  List,
  SelectItem,
  UpsertCategoryRequest,
} from "@/types/state";

@Component({
  components: { ShoppingListDemoBanner },
})
export default class ManageCategories extends Vue {
  formNameRules = [
    (value: string | null): boolean | string =>
      (!!value && value.trim() != "") || "Category name is required",
    (value: string | null): boolean | string =>
      (value && value.length <= 15) ||
      "Category name must be less than 15 characters",
  ];
  squareIcon: string = mdiSquare;
  formValid = false;
  addIcon: string = mdiPlus;
  syncIcon: string = mdiSync;
  editIcon: string = mdiSquareEditOutline;
  deleteIcon: string = mdiTrashCan;
  closeIcon: string = mdiClose;
  dialog = false;
  dialogDelete = false;
  synchronizing = false;
  editedCategory: UpsertCategoryRequest = {
    name: "",
    id: "",
    color: CATEGORY_COLOR_TEAL,
  };

  defaultCategory: UpsertCategoryRequest = {
    name: "",
    id: "",
    color: CATEGORY_COLOR_TEAL,
  };

  @Getter("categoryColorSelectItems") categoryColors!: Array<SelectItem>;
  @Getter("lists") lists!: Array<List>;
  @Getter("items") items!: Array<Item>;
  @Getter("editableCategories") categories!: Array<Category>;
  @Getter("saving") saving!: boolean;
  @Getter("categoryItemsCount") categoryItemsCount!: (
    categoryId: string
  ) => number;

  @Action("upsertCategory") upsertCategory!: (
    request: UpsertCategoryRequest
  ) => Promise<void>;
  @Action("deleteCategory") deleteCategory!: (
    categoryId: string
  ) => Promise<void>;
  @Action("syncCategories") syncCategories!: () => Promise<void>;

  @Action("setTitle") setTitle!: (title: string) => void;
  @Action("loadState") loadState!: () => Promise<void>;

  get dialogWidth(): string {
    return dialogWidth(this.$vuetify.breakpoint.name);
  }

  get formTitle(): string {
    return this.editedCategory.id === this.defaultCategory.id
      ? "Add Category"
      : "Edit Category";
  }

  mounted(): void {
    this.loadState();
    this.setTitle("Manage Categories");
  }

  closePopup(): void {
    this.clearForm();
    this.dialog = false;
  }

  closeDeleteDialog(): void {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.clearForm();
    });
  }

  async onSave(): Promise<void> {
    await this.upsertCategory({
      name: this.editedCategory.name,
      id: this.editedCategory.id,
      color: this.editedCategory.color,
    });
    this.dialog = false;
    this.clearForm();
  }

  onDeleteList(category: Category): void {
    this.editedCategory = { ...category };
    this.dialogDelete = true;
  }

  onDeleteListConfirm(): void {
    this.deleteCategory(this.editedCategory.id);
    this.closeDeleteDialog();
  }

  onEditCategory(category: Category): void {
    this.editedCategory = { ...category };
    this.dialog = true;
  }

  async onSync(): Promise<void> {
    this.synchronizing = true;
    await this.syncCategories();
    this.synchronizing = false;
  }

  clearForm(): void {
    this.editedCategory = {
      ...this.defaultCategory,
    };
  }
}
