



























































import { Component } from "vue-property-decorator";
import Vue from "vue";
import { dialogWidth } from "@/plugins/vuetify";
import { DEFAULT_CURRENCY } from "@/plugins/intl";
import { Action, Getter } from "vuex-class";
import { getFirebaseAuth } from "@/plugins/firebase";
import BackButton from "@/components/BackButton.vue";
import { NotificationRequest, SelectItem } from "@/types/state";
@Component({
  components: { BackButton },
})
export default class SettingsIndex extends Vue {
  dialogDelete = false;
  formValid = false;
  formCurrency = DEFAULT_CURRENCY;

  @Getter("isLoggedIn") isLoggedIn!: boolean;
  @Getter("currency") currency!: string;
  @Getter("saving") saving!: boolean;
  @Getter("currencySelectItems") currencies!: Array<SelectItem>;
  @Action("setCurrency") setCurrency!: (currency: string) => void;
  @Action("setTitle") setTitle!: (title: string) => void;
  @Action("addNotification") addNotification!: (
    request: NotificationRequest
  ) => void;
  @Action("deleteAccount") deleteAccount!: (userId: string) => Promise<void>;

  get dialogWidth(): string {
    return dialogWidth(this.$vuetify.breakpoint.name);
  }

  mounted(): void {
    this.setTitle("Settings");
    this.formCurrency = this.currency;
  }

  closeDeleteDialog(): void {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.clearForm();
    });
  }

  clearForm(): void {
    this.formCurrency = this.currency;
  }

  onDeleteAccount(): void {
    this.dialogDelete = true;
  }

  async onDeleteListConfirm(): Promise<void> {
    try {
      await this.deleteAccount(getFirebaseAuth().currentUser?.uid as string);
      await getFirebaseAuth().currentUser?.delete();
    } catch (e) {
      if (e.code != "auth/requires-recent-login") {
        throw e;
      }
      // The user's credential is too old. They need to sign in again.
      getFirebaseAuth()
        .signOut()
        .then(() => {
          this.addNotification({
            type: "info",
            message: "Please sign in again to delete your account.",
          });
          this.$router.push({
            name: this.$constants.ROUTE_NAMES.LOGIN,
          });
        });
      return;
    }
    this.closeDeleteDialog();
    await this.$router.push({ name: this.$constants.ROUTE_NAMES.HOME });
  }

  onSave(): void {
    this.setCurrency(this.formCurrency);
    this.clearForm();
  }
}
