import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fill-height":"","fluid":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{staticClass:"mt-n16",attrs:{"cols":"12","md":"4","xl":"3"}},[_c('h3',{staticClass:"text-h2 text-center mb-4"},[_vm._v("Login")]),_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"360"}},[_c(VCardText,{staticClass:"px-0"},[_c('div',{ref:"authContainer",attrs:{"id":"firebaseui-auth-container"}}),(!_vm.firebaseUIInitialized)?_c(VProgressCircular,{staticClass:"mx-auto d-block my-16",attrs:{"size":80,"width":5,"color":"primary","indeterminate":""}}):_vm._e()],1)],1),_c('div',{staticClass:"text-center mt-4"},[_c('back-button')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }