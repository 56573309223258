





























import { Vue, Component } from "vue-property-decorator";
import { getPlatformName } from "@/plugins/utils";
import { mdiLogin } from "@mdi/js";
import { Getter } from "vuex-class";
@Component
export default class ShoppingListDemoBanner extends Vue {
  @Getter("isLoggedIn") isLoggedIn!: boolean;
  loginIcon: string = mdiLogin;
  get platformName(): string {
    return getPlatformName();
  }
}
